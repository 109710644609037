import React from "react";
import { partnerLogos } from "./data";
import Slider from "react-slick";
import { useStyles } from "./styles";

const OurPartners = () => {
  const classes = useStyles();
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    pauseOnHover: false,
    speed: 1000,
    slidesToShow: 7,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  return (
    <section className={classes.partnerSection}>
      <div className={classes.carouselWrapper}>
        <Slider {...settings}>
          {partnerLogos.map((item) => (
            <div key={item.id} className={classes.itemsWrapper}>
              <img src={item.logo} alt="" className={classes.logo} />
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default OurPartners;
