import React from 'react';
import { MainLogoBlock } from '../../styles/common/index';
import logoFlyTrip from "../../images/Fly-Trip-300x100.png"

function MainLogo (props) {
    const { history, withReload, withLogo } = props;

    const handleOnClick = () => {
        history.push({
            pathname: '/',
        });

        if (withReload) {
            window.location.reload();
        }
    };

    if (!withLogo) {
        return null;
    }

    return (
        <MainLogoBlock onClick={ () => handleOnClick() }>
            <img
                alt='logo-img'
                src={ logoFlyTrip }
                height='32px'
                width='75px'
            />
        </MainLogoBlock>
    );

}

export default MainLogo;