import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  partnerSection: {
    backgroundColor: "#e9e9e9",
    padding: "48px 0",
    margin: "60px 0",
    boxShadow: "0px 0px 14px 0px #00000030",
  },
  carouselWrapper: {
    backgroundColor: "#ff7043c9",
    padding: "48px 0",
    margin: "48px 0",

    "& .slick-track": {
      display: "flex",
      alignItems: "center",
      gap: 48,
    },
  },

  itemsWrapper: {
    display: "flex !important",
    justifyContent: "center",
    alignItems: "center",
  },

  logo: {
    width: "100%",
    height: "100%",
  },
});
